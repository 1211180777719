@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.nested{
     &__wrapper{
          display: grid;
          grid-template-columns: 25% 1fr;
          grid-gap: 40px;
          margin: 100px 0;
     }
}

.documents{
     &__list{
          display: flex;
          flex-direction: column;
          gap: 12px;
     }
     &__item{
          @include text(20px, 28px, 500, $blue);
          list-style-position: inside;
          a {
               color: $blue !important;
               transition: 0.25s ease;
               &:hover{
                    color: $borderBlue !important;
               }
          }
     }
}

@media screen and (max-width:840px) {
     .nested{
          &__wrapper{
               display: flex;
               flex-direction: column-reverse;
          }
     }
     .documents{
          &__item{
               font-size: 16px;
               line-height: 23px;
          }
     }
}

.ql-align-center{
     text-align: center;
}