@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";


.header{
     &__wrapper{
          padding: 10px 0;
          @include flex();
     }
     &__part{
          &:last-child{
               @include flex(flex-end, center);
          }
     }
     &__home{
          &-img{
               height: 60px;
          }
     }
     &__social{
          &-links{
               @include flex(flex-end, center);
          }
          &-link{
               width: 40px;
               height: 40px;
               @include flex(center, center);
               background: $lightenBlue;
               border-radius: 8px;
               transition: 0.3s ease;
               svg{
                    font-size: 28px;
                    color: white;
               }
               img{
                    width: 24px;
                    height: 24px;
               }
               &:last-child{
                    margin-left: 8px;
               }
          }
     }
     &__search{
          &-toggle{
               width: 32px;
               height: 32px;
               margin-right: 92px;
               svg{
                    font-size: 32px;
                    color: $lightenBlue;
               }
          }
     }
     &__languages{
          height: 40px;
          position: relative;
          margin-left: 18px;
          @include flex(flex-end, center);
          transition: 0.25s ease;
          &-item{
               @include flex(center, center);
               width: 40px;
               height: 40px;
               font-size: 16px;
               font-weight: 600;
               color: $lightenBlue;
               border-radius: 50%;
               transition: 0.3s ease;
               &:hover{
                    cursor: pointer;
               }
          }
          &-bg{
               z-index: -1;
               position: absolute;
               width: 40px;
               height: 40px;
               border-radius: 50%;
               background: $lightenBlue;
               top: 0;
               transition: 0.3s ease !important;
          }
     }
     #instagram:hover{
          //background:linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
          background: #e1306c;
     }
     #facebook:hover{
          background: $facebookHover;
     }
}

a.nav__link{
     padding: 10px 15px;
}

.nav{
     background: $blue;
     &__wrapper{
          display: flex;
          flex-direction: row;
     }
     &__mobile{
          display: none;
     }
     &__link{
          @include text(12px, 20px, 400, white);
          text-transform: uppercase;
          margin: 0 15px;
          &-active{
               background: white;
               color: $blue;
               font-weight: 300;
          }
          &:hover{
               color: $yellow;
          }
     }
     &__dropdown{
          &-toggle{
               position: relative;
               svg{
                    margin-left: 12px;
                    font-size: 12px;
               }
               &:hover{
                    cursor: pointer;
               }
          }
          &-link{
               padding: 10px 15px;
          }
          &-list{
               position: absolute;
               z-index: 999;
               top: 100%;
               left: 0;
               display: none;
               flex-direction: column;
               align-items: stretch;
               &-show{
                    display: flex !important;
               }
          }
          &-item{
               white-space: nowrap;
               @include text(12px, 20px, 400, white);
               text-transform: uppercase;
               padding: 10px 75px 10px 15px;
               background: $blue;
               transition: 0.3s ease;
               &:hover{
                    color: $yellow;
                    .nav__dropdown-toggle{
                         color: white;
                    }
               }
          }
     }
}

@media screen and (max-width: 1280px) {
     .nav{
          &__dropdown{
               &-toggle{
                    padding: 10px 0;
               }
          }
          &__link{
               display: flex;
               justify-content: flex-start;
               align-items: center;
               margin: 0 5px;
          }
     }
}

@media screen and (max-width: 1000px) {
     .nav{
          &__wrapper{
               display: none;
               flex-direction: column;
               &-show{
                    display: flex;
               }
          }
          &__link{
               margin: 0;
          }
          &__dropdown{
               &-toggle{
                    padding: 10px 5px;
               }
               &-list{
                    margin-top: 10px;
                    flex-direction: column;
                    position: relative;
               }
               &-item{
                    padding: 10px 15px 10px 45px;
               }
          }
          &__mobile{
               padding: 10px 0;
               display: flex;
               justify-content: flex-end;
               &-btn{
                    width: 30px;
                    height: 30px;
                    @include flex(center, center);
                    border: 1px solid white;
                    border-radius: 3px;
                    transition: 0.2s ease;
                    i{
                         font-size: 20px;
                         color: #fff;
                    }
                    &:hover{
                         background: white;
                         i{
                              color: $blue;
                         }
                    }
               }
          }
     }
}

@media screen and (max-width: 720px) {
     .header{
          &__home{
               &-img{
                    height: 40px;
               }
          }
          &__social{
               &-link{
                    width: 32px;
                    height: 32px;
                    border-radius: 6px;
                    svg{
                         font-size: 24px;
                    }
               }
          }
          &__languages{
               height: 32px;
               &-item{
                    width: 32px;
                    height: 32px;
                    font-size: 14px;
               }
               &-bg{
                    width: 32px;
                    height: 32px;
               }
          }
     }
}