@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.mail{
     &__wrapper{
          margin: 100px 0;
     }
     &__btn{
          &-list{
               @include flex(flex-start, stracth);
               border-left: 1px solid $grayBorder;
          }
          &-item{
               padding: 16px 24px;
               @include text(18px, 24px, 400, $textGray);
               border-top: 1px solid $grayBorder;
               border-right: 1px solid $grayBorder;
               opacity: 0.6;
               &:hover{
                    cursor: pointer;
               }
               &-active{
                    position: relative;
                    opacity: 1;
                    border-top: 3px solid $yellow;
                    font-weight: 500;
                    &::before {
                         position: absolute;
                         content: '';
                         top: 100%;
                         left: 0;
                         z-index: 1;
                         width: 100%;
                         height: 1px;
                         background: white;
                    }
               }
          }
     }
     &__upload{
          @include flex(flex-start, center);
          gap: 40px;
          &-message{
               @include text(14px, 16px, 400, $textGray);
          }
     }
     &__request{
          padding: 48px 24px;
          border: 1px solid $grayBorder;
          @include text(18px, 24px, 400, $textGray);
          justify-content: center;
     }
     &__blog{
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 40px;
          padding: 24px;
          border: 1px solid $grayBorder;
          &-btns{
               @include flex(flex-start, center);
               gap: 24px;
               margin-bottom: 16px;
          }
          &-btn{
               padding: 12px 20px;
               border: 1px solid $lightBlue;
               border-radius: 4px;
               color: $textGray;
               transition: 0.2s ease;
               &-active{
                    background: $lightBlue;
               }
               &:hover{
                    cursor: pointer;
                    background: $lightBlue;
               }
          }
     }
     &__textarea{
          resize: none;
     }
     &__title{
          @include text(14px, 20px, 700, $textGray);
          margin-bottom: 5px;
     }
     &__form{
          h1{
               @include text(30px, 30px, 500, $textGray);
               text-align: center;
               margin-bottom: 20px;
          }
          width: 560px;
          //border: 1px solid #dcdcdc;
          display: flex;
          flex-direction: column;
          #files{
               display: none;
          }
     }
     &__input{
          border: 1px solid #dcdcdc;
          padding: 8px 12px;
          margin-bottom: 10px;
     }
     &__submit{
          margin: 20px auto 0;
          display: block;
          @include text(14px, 20px, 400, white);
          text-transform: uppercase;
          background: $btnBlue !important;
          padding: 15px 30px;
          border-radius: 8px;
          transition: 0.3s ease;
          &:hover{
               background: $borderBlue !important;
          }
     }
     &__files{
          max-width: 180px;
          @include text(14px, 20px, 700, $textGray);
          padding: 10px;
          border: 2px solid $yellow;
          border-radius: 4px;
          text-align: center;
          transition: 0.3s ease;
          &:hover{
               background-color: $yellow;
          }
     }
     &__result{
          margin-top: 12px;
          @include flex(flex-start, center);
          gap: 16px;
          &-border{   
               width: 5px;
               border-radius: 2px;
               height: 100%;
               &-green{
                    background: $green;
               }
               &-red{
                    background: $red;
               }
               &-yellow{
                    background: $yellow;
               }
          }
          &-text{
               padding: 8px 0;
               @include text(14px, 16px, 500, $textGray);
          }
     }
}