@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.page__sidebar{
     &-links{
          position: relative;
          &::before{
               position: absolute;
               content: "";
               width: 1px;
               height: calc(100% - 25px);
               background: #dcdcdc;
               top: 0;
               right: calc(100% - 20px);
          }
     }
     &-link{
          display: block;
          position: relative;
          border-bottom: 1px solid #dcdcdc;
          padding: 16px 20px;
          @include text(14px, 18px, 400, $blue);
          transition: 0.1s ease;
          &-child{
               margin-left: 40px;
               &::before{
                    position: absolute;
                    content: "";
                    width: 20px;
                    height: 1px;
                    background: #dcdcdc;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 100%;
               }
               &:hover{
                    cursor: pointer;
               }
          }
          &-active{
               font-weight: 500;
               .page__sidebar-link-border{
                    background: $yellow;
               }
          }
          &-border{
               position: absolute;
               width: 2px;
               height: 100%;
               left: 0;
               top: 0;
               background: #dcdcdc;
               transition: 0.2s ease;
          }
          &:hover{
               font-weight: 500;
          }
     }
}

.page{
     &__title{
          @include flex(center, flex-start);
     }
     &__content{
          @include text(18px, 22px, 400, $darkGray);
          li {
               list-style-position: inside;
          }
          h1, h2, h3, h4, h5, h6 {
               font-size: 24px;
               line-height: 32px;
          }
     }
}

@media screen and (max-width: 840px) {
     .page{
          &__content{
               font-size: 15px;
               line-height: 18px;
          }
     }
}